<template>
  <div>
    <v-dialog v-model="dialog" persistent width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="SetValues(news)"
          color="primary"
          >mdi-folder-image</v-icon
        >
      </template>
      <v-card flat class="card">
        <v-toolbar outlined flat color="primary lighten-2">
          <v-toolbar-title>
            أضافة صور
            {{ this.$route.meta.single }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn :disabled="loading" @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="form" lazy-validation>
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <!-- <v-col
                    cols="12"
                    md="3"
                   
                  > -->
                  <v-card
                    elevation="8"
                    class="ma-3 position-relative"
                    v-for="(img, i) in attachments"
                    :key="i"
                  >
                    <v-btn
                      :loading="img.loading"
                      fab
                      width="20"
                      height="20"
                      absolute
                      style="right: 0px"
                      class="position-absolute"
                      color="primary"
                      @click="deleteImage(img)"
                    >
                      <v-icon small color="white">mdi mdi-close</v-icon>
                    </v-btn>
                    <v-img
                      contain
                      width="150"
                      height="150"
                      :src="img.path"
                      :lazy-src="img.path"
                    ></v-img>
                  </v-card>
                  <!-- </v-col> -->
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  @click="close()"
                  x-large
                  :disabled="loading"
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >إلغاء</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["news"],
  data() {
    return {
      loading: false,
      dialog: false,
      attachments: [],
    };
  },

  mounted() {},

  methods: {
    SetValues(item) {
      this.attachments = [];
      let attachments = item.attachments;
      if (Array.isArray(attachments)) {
        attachments.forEach((attachment) => {
          let obj = {
            id: attachment.id,
            name: attachment.name,
            path: attachment.path,
            type: attachment.type,
            createdAt: attachment.createdAt,
          };
          this.attachments.push(obj);
        });
      } else {
        this.attachments = [];
      }
      console.log(attachments);
    },
    reset() {
      this.attachments = [];
      let attachments = this.news.attachments;
      if (Array.isArray(attachments)) {
        attachments.forEach((attachment) => {
          this.attachments.push({
            id: attachment.id,
            name: attachment.name,
            path: attachment.path,
            type: attachment.type,
            createdAt: attachment.createdAt,
            loading: false,
          });
        });
      } else {
        this.attachments = [];
      }
    },

    submit() {
      this.editItem();
    },
    removeImage(id) {
      // Find the index in the local attachments array
      const localIndex = this.attachments.findIndex((item) => item.id === id);

      // Find the index in the global news attachments array
      const globalIndex = this.news.attachments.findIndex(
        (item) => item.id === id
      );

      // Check if the local index is greater than or equal to 0 (found)
      if (localIndex >= 0) {
        // Remove the item at the found index from the local attachments array
        this.attachments.splice(localIndex, 1);
      }

      // Check if the global index is greater than or equal to 0 (found)
      if (globalIndex >= 0) {
        // Remove the item at the found index from the global news attachments array
        this.news.attachments.splice(globalIndex, 1);
      }
    },
    async deleteImage(img) {
      img.loading = true;
      this.loading = true;
      await this.$http
        .delete(`${this.$route.meta.endPoint}/removeImage/${img.id}`)
        .then((res) => {
          this.removeImage(img.id);
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          img.loading = false;
        });
    },

    close() {
      this.reset();
      this.resetValidation();
      this.dialog = false;
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getStringErrors(obj) {
      // console.log(obj,typeof(obj));
      let result = "";

      // Check if the object has a message of type string
      if (obj.message && typeof obj.message === "string") {
        result += `${obj.message}\n`;
      }

      // Check if the object has an inner object named errors
      if (obj.errors && typeof obj.errors === "object") {
        console.log("in");
        // Concatenate key-value pairs from the errors object
        for (const key in obj.errors) {
          if (Object.prototype.hasOwnProperty.call(obj.errors, key)) {
            // Ensure that the value is a string before appending to the result
            if (typeof obj.errors[key] === "string") {
              result += `${key}: ${obj.errors[key]}\n`;
            }
          }
        }
      }

      return result.trim(); // Trim to remove trailing newline
    },
  },
};
</script>

<style scoped>
.btn-delete-file {
  padding: 11px;
  font-weight: bold;
}
</style>
