<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :disabled="loading"
          v-bind="attrs"
          v-on="on"
          @click="SetValues(news)"
          color="info"
          >edit</v-icon
        >
      </template>
      <v-card flat class="card">
        <v-toolbar outlined flat color="primary lighten-2">
          <v-toolbar-title>
            تعديل
            {{ this.$route.meta.single }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" lazy-validation>
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            العنوان
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.title"
                                            placeholder="العنوان"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col> -->

                  <!-- description -->
                  <v-col cols="12" md="12">
                    <h4 class="mb-2">الوصف</h4>
                    <v-textarea
                      v-model="item.caption"
                      required
                      outlined
                      filled
                      hide-details="auto"
                      label="لا توجد ملاحضات"
                      color="secondary"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="loading"
                  x-large
                  color="primary"
                  class="btn"
                  :disabled="!item.caption"
                  >حفظ</v-btn
                >
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  :disabled="loading"
                  class="mr-2 btn"
                  outlined
                  >إلغاء</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["news"],

  data() {
    return {
      loading: false,
      dialog: false,
      item: {
        caption: "",
      },
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    SetValues(item) {
      this.item.caption = item.caption ?? "";
    },
    reset() {
      this.item = {
        caption: "",
      };
    },

    submit() {
      this.editItem();
    },

    async editItem() {
      this.loading = true;
      let request = {
        caption: this.item.caption,
      };
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.news.id}`, request)
        .then((res) => {
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    close() {
      this.reset();
      this.resetValidation();
      this.dialog = false;
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getStringErrors(obj) {
      // console.log(obj,typeof(obj));
      let result = "";

      // Check if the object has a message of type string
      if (obj.message && typeof obj.message === "string") {
        result += `${obj.message}\n`;
      }

      // Check if the object has an inner object named errors
      if (obj.errors && typeof obj.errors === "object") {
        console.log("in");
        // Concatenate key-value pairs from the errors object
        for (const key in obj.errors) {
          if (Object.prototype.hasOwnProperty.call(obj.errors, key)) {
            // Ensure that the value is a string before appending to the result
            if (typeof obj.errors[key] === "string") {
              result += `${key}: ${obj.errors[key]}\n`;
            }
          }
        }
      }

      return result.trim(); // Trim to remove trailing newline
    },
  },
};
</script>

<style scoped>
.btn-delete-file {
  padding: 11px;
  font-weight: bold;
}
</style>
