<template>
  <div>
    <v-dialog v-model="dialog" persistent width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="teal" @click="reset()"
          >mdi-image-plus</v-icon
        >
      </template>
      <v-card flat class="card">
        <v-toolbar outlined flat color="primary lighten-2">
          <v-toolbar-title>
            أضافة صور
            {{ this.$route.meta.single }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn :disabled="loading" @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" lazy-validation>
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h4 class="mb-2">صور للمادة</h4>
                    <MultipartFileUpload
                      accept="image/*;capture=camera"
                      formData="files"
                      buttonText="رفع صـور للمادة"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="loading"
                  x-large
                  :disabled="$store.state.files[0] == null"
                  color="primary"
                  class="btn"
                  >أضافة</v-btn
                >
                <v-btn
                  @click="close()"
                  x-large
                  :disabled="loading"
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >إلغاء</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MultipartFileUpload from "@/components/MultipartFileUpload.vue";
export default {
  props: ["news"],
  components: {
    MultipartFileUpload,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      attachments: [],
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.$store.state.files = [];
      this.$store.state.previews = [];
      //   console.log(this.$store.state.files);
      //   this.$eventBus.$emit(`refresh`);
      //   this.$refs.uploader.click();
    },

    submit() {
      this.editItem();
    },

    async editItem() {
      this.loading = true;
      let formData = new FormData();
      if (this.$store.state.files[0] != null) {
        this.$store.state.progress = true;
        for (const i of Object.keys(this.$store.state.files)) {
          console.log(this.$store.state.files[i]);
          formData.append("files", this.$store.state.files[i]);
        }
        /*  await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then(res => {
                        this.item.attachments = res.data.result.fileList;
                    });*/
      }
      await this.$http
        .post(
          `${this.$route.meta.endPoint}/addImage/${this.news.id}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        )
        .then((res) => {
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$emit(`refresh`);
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    close() {
      this.reset();
      this.resetValidation();
      this.dialog = false;
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getStringErrors(obj) {
      // console.log(obj,typeof(obj));
      let result = "";

      // Check if the object has a message of type string
      if (obj.message && typeof obj.message === "string") {
        result += `${obj.message}\n`;
      }

      // Check if the object has an inner object named errors
      if (obj.errors && typeof obj.errors === "object") {
        console.log("in");
        // Concatenate key-value pairs from the errors object
        for (const key in obj.errors) {
          if (Object.prototype.hasOwnProperty.call(obj.errors, key)) {
            // Ensure that the value is a string before appending to the result
            if (typeof obj.errors[key] === "string") {
              result += `${key}: ${obj.errors[key]}\n`;
            }
          }
        }
      }

      return result.trim(); // Trim to remove trailing newline
    },
  },
};
</script>

<style scoped>
.btn-delete-file {
  padding: 11px;
  font-weight: bold;
}
</style>
