<template>
  <div>
    <v-row align="center" class="mb-3">
      <v-col cols="12" md="2">
        <v-btn @click="openAddDialog()" color="primary" large class="btn">
          <v-icon class="ml-2">add_circle_outline</v-icon>
          <h3>إضافة {{ this.$route.meta.single }}</h3>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="4">
        <!-- v-debounce:500ms="getNews(1, filter.pageSize, filter.search)"  -->
        <v-text-field
          class="mr-1"
          @input:2000ms="getNews(1, filter.pageSize, filter.search)"
          v-model="filter.search"
          label="الإسم"
          hide-details
          prepend-inner-icon="search"
          dense
          outlined
          filled
        />
      </v-col>
    </v-row>
    <v-card id="card">
      <v-data-table
        class="mytable"
        :items="news"
        :loading="loading"
        :headers="headers"
        :search="filter.search"
        hide-default-footer
        loading-text="جاري تحميل البيانات"
        no-data-text="لا توجد بيانات"
        :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:[`item.id`]="{ item }">
          <span>{{ news.indexOf(item) + 1 }}</span>
        </template>
        <template v-slot:[`item.caption`]="{ item }">
          <div>{{ item.caption.substring(0, 20) }}...</div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <div>
            {{ new Date(item.createdAt).toISOString().substring(0, 10) }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <show-images
                  @refresh="
                    getNews(filter.pageIndex, filter.pageSize, filter.search)
                  "
                  :news="item"
                ></show-images>
              </v-btn>
            </template>
            <span>أضافة صور</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <remove-images :news="item"></remove-images>
              </v-btn>
            </template>
            <span>عرض الصور</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <edit v-on="on" :news="item"></edit>
              </v-btn>
            </template>
            <span>تعديل</span>
          </v-tooltip>
          <!-- class="x" -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click.stop="deleteItem(item.id, index)" v-on="on" icon>
                <v-icon color="error">delete</v-icon>
              </v-btn>
            </template>
            <span>حذف</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="d-flex justify-space-between py-3">
      <v-spacer></v-spacer>
      <v-pagination
        v-model="filter.pageIndex"
        :length="length"
        :total-visible="7"
        @input="getNews(filter.pageIndex, filter.pageSize, filter.search)"
      ></v-pagination>
    </div>
    <Dialog />
  </div>
</template>

<script>
import Edit from "./edit.vue";
import RemoveImages from "./removeImages.vue";
import ShowImages from "./showImages.vue";
export default {
  components: {
    Dialog: () => import("./dialog"),
    Edit,
    ShowImages,
    RemoveImages,
  },
  data() {
    return {
      headers: [
        {
          text: "الوصف",
          value: "caption",
        },
        {
          text: "الصورة",
          value: "attachments.path",
        },

        {
          text: " تاريخ الاضافة",
          value: "createdAt",
        },
        {
          text: "الإجراءات",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      news: [],
      newsObj: {},
      loading: false,
      uploading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      length: 0,
      filter: {
        pageIndex: 1,
        pageSize: 15,
        search: "",
        count: 0,
      },
      pageSizes: [5, 10, 15, 30, 50, 100],
    };
  },

  async mounted() {
    await this.getNews(
      this.filter.pageIndex,
      this.filter.pageSize,
      this.filter.search
    );
    this.$eventBus.$on(`refresh`, async () => {
      await this.getNews(
        this.filter.pageIndex,
        this.filter.pageSize,
        this.filter.search
      );
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },

  methods: {
    async getNews(pageIndex, pageSize, search) {
      this.loading = true;
      try {
        let query = `News?IsDelete=false`;

        if (pageIndex !== undefined && pageIndex !== null) {
          query += `&PageIndex=${pageIndex}`;
        }

        if (pageSize !== undefined && pageSize !== null) {
          query += `&PageSize=${pageSize}`;
        }

        if (search !== undefined && search !== null) {
          query += `&Search=${search}`;
        }

        const res = await this.$http.get(query);
        console.log(res.data);
        this.news = res.data.result.data;
        this.filter.count = res.data.result.count;
        this.length =
          res.data.result.count === undefined
            ? 0
            : Math.ceil(res.data.result.count / this.filter.pageSize);
      } catch (err) {
        this.dispatch("toastification/setToast", {
          message: `${err.data.message}`,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    getItems() {
      this.$global.dispatch(
        `get${this.$route.meta.endPoint}`,
        this.$route.meta.role
      );
    },

    async deleteItem(id, i) {
      this.$genericService.swalAlertConfirm({}).then((result) => {
        if (result.isConfirmed) {
          this.$genericService
            .delete(`${this.$route.meta.endPoint}`, id)
            .then((res) => {
              this.$eventBus.$emit(`refresh`);

              // this.$genericService.swalAlertSuccess();
              this.$store.dispatch("toastification/setToast", {
                message: `${res.data.message}`,
                type: "success",
              });
              this.news.splice(i, 1);
            })
            .catch((err) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${err.data.message}`,
                type: "error",
              });
            });
        }
      });
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
};
</script>
<style scoped>
.x {
  position: absolute !important;
  left: 0px;
  top: 0px;
  z-index: 4 !important;
}
</style>
